<template>
  <div class="news">
    <div style="height: 5.625rem"></div>
    <!-- 头部 -->
    <div
      class="pt-60 pb-50 bg-white flex align-center justify-center flex-column font-30 text-222222"
    >
      消息
      <div class="mt-25 flex">
        <div class="mr-30" :class="type == 1 ? 'btn1' : 'btn2'" @click="cuttype(1)">
          互动
        </div>
        <div class="mr-30" :class="type == 2 ? 'btn1' : 'btn2'" @click="cuttype(2)">
          互动消息
        </div>
        <!-- <div class="mr-30" :class="type == 2 ? 'btn1' : 'btn2'" @click="cuttype(2)">
          通知
        </div>
        <div :class="type == 3 ? 'btn1' : 'btn2'" @click="cuttype(3)">私信</div> -->
      </div>
    </div>
    <!-- 详情 -->
    <div class="py-40 px-525">
      <div class="p-45 bg-white">
        <div class="flex align-center mb-25" v-if="type == 1">
          <div
            v-for="(item, index) in list1"
            :key="index"
            class="mr-25"
            :class="type2 == item.id ? 'btn3' : 'btn4'"
            @click="cuttype2(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="type == 1">
          <div v-for="(item, index) in Info" :key="index" class="flex align-center">
            <div class="wh70 circle mr-25">
              <!-- <img :src="item.avatar" class="circle" alt="" /> -->
              <el-image class="circle wh70" :src="item.avatar" fit="cover"></el-image>
            </div>
            <div
              class="x-1 flex align-center justify-between py-30 border-bottom border-F1F2F3"
            >
              <div>
                <div class="text-222222 font-weight-bolder mb-10">
                  {{ item.username }}
                </div>
                <div class="text-999999">
                  <span v-if="item.type == 2">评论了你的原创视频</span>
                  <span v-if="item.type == 3">赞了你</span>
                  <span v-if="item.type == 4">关注了你</span>
                  <span v-if="item.type == 5">访问您的主页</span>
                </div>
              </div>
              <div class="text-999999">
                {{ item.create_time }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="type == 2">
          <div v-for="(item, index) in Info" :key="index" class="flex align-center">
            <div class="wh70 circle mr-25">
              <!-- <img src="@/assets/img/icon_news.png" class="circle" alt="" /> -->
              <el-image
                class="circle"
                :src="require('@/assets/img/icon_news.png')"
                fit="cover"
              ></el-image>
            </div>
            <div
              class="x-1 flex align-center justify-between py-30 border-bottom border-F1F2F3"
            >
              <div>
                <div class="text-222222 font-weight-bolder mb-10">
                  {{ item.title }}
                </div>
                <div class="text-999999">
                  {{ item.content }}
                </div>
              </div>
              <div class="text-999999">
                {{ item.create_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
      <!-- :total="1000" -->
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      type: 1,
      type2: 1,
      Info: [],
      list1: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "评论",
        },
        {
          id: 3,
          name: "点赞",
        },
        {
          id: 4,
          name: "关注",
        },
      ],
      page_nums: 0,
      page: 1,
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      if (this.type == 1) {
        req
          .post("news/interaction", {
            type: this.type2,
            page: this.page,
          })
          .then((res) => {
            if (res.code != 200) {
              return;
            }
            that.Info = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        req
          .post("news/notice", {
            page: this.page,
          })
          .then((res) => {
            if (res.code != 200) {
              return;
            }
            that.Info = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cuttype2(e) {
      this.type2 = e;
      this.getdata();
    },
    cuttype(e) {
      // if (e) {
      //   this.$router.push({
      //     path: "/chat",
      //     query: {},
      //   });
      // } else {
      this.type = e;
      this.getdata();
      // }
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: rgb(241, 242, 243);
}
</style>
<style scoped lang="scss">
.news {
  .wh70 {
    width: 4.375rem;
    height: 4.375rem;
    img {
      width: 4.375rem;
      height: 4.375rem;
    }
  }
  .btn1 {
    width: 7.5rem;
    height: 2.5rem;
    background: linear-gradient(0deg, #ff534d, #ff8f6d);
    border-radius: 1.25rem;
    line-height: 2.5rem;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
  .btn2 {
    width: 7.5rem;
    height: 2.5rem;
    background: #dadada;
    border-radius: 1.25rem;
    line-height: 2.5rem;
    font-size: 1rem;
    color: #333333;
    text-align: center;
    cursor: pointer;
  }
  .btn3 {
    width: 7.5rem;
    height: 2.5rem;
    background: linear-gradient(0deg, #ff534d, #ff8f6d);
    border-radius: 0.3125rem;
    line-height: 2.5rem;
    font-size: 1rem;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .btn4 {
    width: 7.5rem;
    height: 2.5rem;
    background: #e9e9e9;
    border-radius: 0.3125rem;
    line-height: 2.5rem;
    font-size: 1rem;
    text-align: center;
    color: #222222;
    cursor: pointer;
  }
}
</style>
